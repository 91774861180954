import request from '../request'



//微信登录接口
export const weiXinLogin = (params) => {
  return request({
      url: `/blade-party-admin/min/weiXin/login`,
      params: params,
      method: 'GET',
      headers: {
          'Authorization': 'Basic c3dvcmQ6c3dvcmRfc2VjcmV0'
      }
  })
};

export const ActivityList = (params) => {
  return request({
      url: `/blade-party-admin/activity/info/page?current=1&size=1`,
      method: 'GET',
      params: params,
      headers: {
          'Authorization': 'Basic c3dvcmQ6c3dvcmRfc2VjcmV0'
      }
  })
};