<template>
  <div class="bgc">
    <div v-if="state">
      <!-- 上 -->
      <div class="partytop">
        <div class="partyno">
          <span class="partynofra" @click="gotoNext('organization')"
            >党组织架构</span
          >
        </div>
      </div>
      <!-- 中 -->
      <div class="party">
        <div class="partyleft" @click="gotoNext('pairSupport')">
          <div class="assistance">结对帮扶</div>
          <div class="evenmore" @click="gotoNext('pairSupport')">查看更多</div>
        </div>
        <div class="partyright">
          <div class="partop" @click="gotoNext('taskList')">
            <span>党员任务</span>
          </div>
          <div class="parbottom" @click="gotoNext('memberInfo')">
            <span>党员信息</span>
          </div>
        </div>
      </div>
      <!-- 下 -->
      <div>
        <div class="activity">
          <div class="activitytop">
            <div class="actleft">党员活动</div>
            <div class="actright" @click="gotoNext('activityList')">
              <span>查看更多</span>
              <img src="./imgs/icon-列表右箭头：灰-16备份 4@3x.png" alt="" />
            </div>
          </div>
          <div class="activitybotton" @click="gotoDetail(ActivityList)">
            <div class="acttop">
              <img  v-if="ActivityList.websiteBanner" :src="ActivityList.websiteBanner" alt="" />
              <!-- <img v-else="!ActivityList.websiteBanner" src="./imgs/底图@3x.png" alt="" /> -->
              <div class="supernatant">参与人：{{ActivityList.enrollCount}}<span>/{{ActivityList.activityNum}}</span></div>
            </div>
            <div class="actbottom">
              <div class="top">{{ActivityList.activityName}}</div>
              <div class="center">活动时间：{{ActivityList.activityStartTime}}</div>
              <div class="botton">
                <div class="btom">{{ActivityList.readCount}}个人浏览</div>
                <div v-if="ActivityList.stop" class="button" style="background-color: #CCC;">已结束</div>
                <div v-if="ActivityList.enrollStatus>0&&ActivityList.stop===false" class="button">已报名</div>
                <div v-if ="ActivityList.enrollStatus===0&&ActivityList.enrollCount!==ActivityList.activityNum&&ActivityList.stop===false" class="button">立即报名</div>
                <div v-if="ActivityList.enrollStatus===0&&ActivityList.enrollCount===ActivityList.activityNum&&ActivityList.stop===false" class="button">人员已满</div>
                 <!-- <div
                    v-if="ActivityList.enrollStatus===0&&ActivityList.enrollCount!==ActivityList.activityNum&&selectRow===0"
                    class="baomingButton backRed"
                    @click="getBaomingPost(item)"
                  >立即报名</div>
                  <div
                    v-if="ActivityList.enrollStatus===1&&selectRow===0"
                    class="baomingButton backWhite"
                  >已报名</div>
                  <div
                    v-if="ActivityList.enrollStatus===0&&ActivityList.enrollCount===ActivityList.activityNum&&selectRow===0"
                    class="baomingButton backGrey"
                  >人数已满</div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="box">没有权限请联系管理员</div>
    </div>
  </div>
</template>

<script>
import { getActivityList, praisePost, baomingPost } from '../../api/activity';
import { weiXinLogin } from '../../api/mainPageAnder';
import { Notify } from 'vant';
export default {
  name: 'MainPageAnder',

  data() {
    return {
      state: true,
      ActivityList:''
    };
  },

  mounted() {},
  created(){
    this.comFormWeixin()
  },
  methods: {
    // 跳转活动详情
     gotoDetail(item) {
      const {id , stop} = item
      const selectRow = stop==true?1:0
      this.$router.push({
        name: 'activityDetail',
        query: { id , selectRow}
      });
    },
    comFormWeixin(){
      var loginType = this.getQueryVariable("loginType") == false
          ? this.getQueryVariables("loginType")
          : this.getQueryVariable("loginType");
      if (loginType && loginType.indexOf("&") != -1) {
        loginType = loginType.split("&")[0];
      }
      // 同兴乡小程序token
      var token = this.getQueryVariable("token") == false
              ? this.getQueryVariables("token")
              : this.getQueryVariable("token");
      localStorage.setItem('loginType', loginType);
      console.log("微信传送参数loginType",loginType);
      console.log("微信传送参数token",token);
      // 登录
      weiXinLogin({token:token}).then(res => {
        console.log("微信跳转获取信息成功",res);
        if(res.success){
          this.state = true
          const result = res.data;
          localStorage.setItem(
            'Blade-Auth-Token',
            `${result.token_type} ${result.access_token}`
          );
          localStorage.setItem('roleName', result.role_name);
          localStorage.setItem('Avatar', result.avatar);
          localStorage.setItem('userName', result.user_name);
          localStorage.setItem('nickName', result.nick_name);
          this.getActivityList()
        }else{
          console.log("获取token失败")
          this.state = false
        }
      })
      .catch(err => {
        console.log("获取token失败,err:",err)
      });
      
    },
    getQueryVariable(variable) {
      let query = window.location.href.substring(1);
      let vars = query.split("&");
      for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },
    getQueryVariables(variable) {
      let query = window.location.href.substring(1);
      let vars = query.split("?");
      for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },
    gotoNext(name) {
      if (name) {
        if (name.indexOf('http') > -1) {
          // dd.biz.util.openLink({
          //   url: name, //要打开链接的地址
          //   onSuccess: function (result) {
          //     /**/
          //   },
          //   onFail: function (err) {},
          // });
        } else {
          if (name == 'gridMap') {
            if (
              localStorage.getItem('tenantId').substring(0, 9) == '330702101'
            ) {
              this.$router.push({ name: name });
            }
          } else {
            this.$router.push({ name: name });
          }
        }
      }
    },
     // 获取活动列表
    getActivityList(){
      getActivityList({
        current: 1,
        size: 1
      }).then(res => {
        if(res.success){
          this.ActivityList = res.data.records[0]
          console.log(this.ActivityList);
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
$px: 1rem/75;
.bgc {
  min-height: 100%;
  background-color: #f5f5f5;
}
.box {
  width: 100%;
  padding-top: 500 * $px;
  font-size: 32 * $px;
  color: #969799;
  text-align: center;
}
.partytop {
  background-color: #999;
  background-image: url('./imgs/底图@3x.png');
  margin-bottom: 66 * $px;
  width: 750 * $px;
  height: 480 * $px;
  background-size: 100% 100%;
  position: relative;
}
.partyno {
  position: absolute;
  top: 288 * $px;
  left: 32 * $px;
  padding-top: 18 * $px;
  width: 686 * $px;
  height: 208 * $px;
  background-image: url('./imgs/DSC_8042-1@3x.png');
  background-size: 100% 100%;
}
.partynofra {
  margin-left: 98 * $px;
  color: #ff3c31ff;
  font-size: 40 * $px;
  font-weight: 700;
}
.party {
  display: flex;
  justify-content: space-between;
  height: 300 * $px;
  width: 686 * $px;
  margin: 0 auto;
}
.partyleft {
  background-image: url('./imgs/位图@3x.png');
  background-size: 100% 100%;
  padding-left: 24 * $px;
  width: 336 * $px;
  height: 262 * $px;
}
.party .partyright {
  width: 336 * $px;
  height: 262 * $px;
  font-size: 32 * $px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.party .partyright .partop,
.party .partyright .parbottom {
  width: 336 * $px;
  height: 124 * $px;
}
.party .partyright .partop {
  background-image: url('./imgs/位图@3x(1).png');
  background-size: 100% 100%;
}
.party .partyright .parbottom {
  background-image: url('./imgs/位图@3x(2).png');
  background-size: 100% 100%;
}
.activity {
  width: 686 * $px;
  margin: 0 auto;
}
.activity .activitytop {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20 * $px;
}
.activity .activitytop .actleft {
  font-size: 40 * $px;
  font-weight: 700;
}
.activity .activitytop .actright {
  margin-top: 8 * $px;
  font-size: 24 * $px;
}
.activity .activitytop .actright img {
  width: 32 * $px;
  height: 32 * $px;
}
.activity .activitybotton .acttop img {
  width: 100%;
  height: 360 * $px;
}
.activity .activitybotton {
  height: 604 * $px;
}
.activity .activitybotton .actbottom {
  margin-top: 20 * $px;
  display: flex;
  flex-direction: column;
  padding-left: 24 * $px;
  padding-right: 24 * $px;
}
.button {
  width: 208 * $px;
  height: 72 * $px;
  background: #ff6010;
  border-radius: 43 * $px;
  font-size: 28 * $px;
  color: #fff;
  text-align: center;
  line-height: 72 * $px;
}
.activity .activitybotton .actbottom .botton {
  // height: 72 * $px ;

  display: flex;
  justify-content: space-between;
}
.activity .activitybotton .actbottom .top {
  font-size: 36 * $px;
  font-weight: 700;
}
.activity .activitybotton .actbottom .center {
  font-size: 24 * $px;
  height: 64 * $px;
  line-height: 64 * $px;
}
.activity .activitybotton .actbottom .botton .btom {
  color: #999999ff;
  font-size: 24 * $px;
  height: 72 * $px;
  line-height: 72 * $px;
}
.activity .activitybotton .acttop {
  position: relative;
}
.supernatant {
  position: absolute;
  top: 290 * $px;
  left: 436 * $px;
  width: 238 * $px;
  height: 54 * $px;
  text-align: center;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 32 * $px;
  font-size: 28 * $px;
  color: #ffffff;
  line-height: 54 * $px;
}
.party .partyleft .evenmore {
  width: 116 * $px;
  height: 40 * $px;
  font-size: 18 * $px;
  line-height: 40 * $px;
  text-align: center;
  background-image: url('./imgs/矩形 2@3x.png');
  background-size: 100% 100%;
}
.assistance {
  font-size: 32 * $px;
  height: 82 * $px;
  line-height: 82 * $px;
  font-weight: 700;
  color: #e5400bff;
}
.party .partyright .partop {
  padding-top: 24 * $px;
  padding-left: 16 * $px;
  color: #0096f9ff;
}
.party .partyright .parbottom {
  padding-top: 24 * $px;
  padding-left: 16 * $px;
  color: #f4002cff;
}
</style>
