
import request from '../request'

// export const login = (params) => {
//     return axios.post(`/api/blade-party-admin/dict-biz/dictionary${params.param}`, params)
//         .then(res => res)
//         .catch(err => { console.log(err) });
// };
const auth = '';
// 获取党建风采
export const getActivityList = (params) => {
    return request({
        url: `/blade-party-admin/min/activity/list`,
        params: params,
        method: 'GET',
        headers: {
            'Blade-Auth': auth
        }
    })
};
// 活动点赞

export const praisePost = (params) => {
    return request({
        url: `/blade-party-admin/min/activity/praise`,
        data: params,
        method: 'POST',
        headers: {
            'Blade-Auth': auth
        }
    })
};
// 活动报名
export const baomingPost = (params) => {
    return request({
        url: `/blade-party-admin/min/activity/enroll`,
        data: params,
        method: 'POST',
        headers: {
            'Blade-Auth': auth
        }
    })
};

// 活动详情
export const activityDetail = (params) => {
    return request({
        url: `/blade-party-admin/min/activity/detail/${params.id}`,
        params: params,
        method: 'GET',
        headers: {
            'Blade-Auth': auth
        }
    })
};

// 阅读次数加一
export const readMountPlus = (params) => {
    return request({
        url: `/blade-party-admin/min/activity/read`,
        data: params,
        method: 'POST',
        headers: {
            'Blade-Auth': auth
        }
    })
};
// 获取评论列表
export const commentsList = (params) => {
    return request({
        url: `/blade-party-admin/min/review/page`,
        params: params,
        method: 'GET',
        headers: {
            'Blade-Auth': auth
        }
    })
};

export const commentsPost = (params) => {
    return request({
        url: `/blade-party-admin/min/review/submit`,
        data: params,
        method: 'POST',
        headers: {
            'Blade-Auth': auth
        }
    })
};
// 活动签到接口
export const signInActivity = (params) => {
    return request({
        url: `/blade-party-admin/min/activity/sign`,
        data: params,
        method: 'POST',
        headers: {
            'Blade-Auth': auth
        }
    })
};